
import React, {useContext} from 'react';
import { useIntl } from "gatsby-plugin-intl"
import { Layout, SEO } from 'components/common';
import { Intro, Projects, Skills, GithubRepos, Companies } from 'components/landing';
import sakura from 'assets/company-logos/sakuratokyo-logo.png'
import imaid from 'assets/company-logos/imaid-logo.png'
import { Contact } from 'components/contact'


export default () => {
  const intl = useIntl()
  
  return (
  <Layout>
    <SEO />
    <Contact />
  </Layout>);
};
